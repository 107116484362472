@import "minima";

.center-image {
    margin: 0 auto;
    display: block;
}

.video-holder {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  margin-bottom: 10px;
}

.video-holder iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-holder video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-border {
  background: black;
  border: 10px solid black;
}

figcaption {
  text-align: center;
  color: rgba(117, 117, 117, 1);
}